import React, { useState, useEffect } from 'react'
import './Ti.css'
import ProductsFooter from '../../products/footer/ProductsFooter';
import AnaliticaCard from './AnaliticaCard';
import RedesCard from './RedesCard';
import ServidoresCard from './ServidoresCard';
import MonitoreoCard from './MonitoreoCard';
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'

const { Backspace, Delay } = Typist

const analiticaURL = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fanalitica.png?alt=media&token=18a306b7-e583-414a-be50-e9a0a7dba83e'
const redesURL = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fredes.png?alt=media&token=ae3edc9f-575d-4999-a70d-059e545e4aa8'
const servidoresURL = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fservidores.png?alt=media&token=492fbf9b-f187-47fb-a896-4b302aeddfd4'
const monitoreoURL = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fmonitoreo.png?alt=media&token=b7483782-210d-4228-b0cd-13d5aac4b589'

const Ti = ({setHome}) => {

    const [ typist, setTypist ] = useState(false)
    
    const TypistDraw = () => 
                    typist ? <Typist avgTypingDelay={50} onTypingDone={() => setTypist(false)}>
                        <Delay ms={300}/>
                        <span>Analítica de datos.</span>    
                        <Backspace count={19} delay={1000}/>
                        <span>Servidores.</span>    
                        <Backspace count={11} delay={1000}/>
                        <span>Redes.</span>    
                        <Backspace count={6} delay={1000}/>
                        <span>Monitoreo de medios.</span>    
                        <Backspace count={20} delay={1000}/>    
                    </Typist> : null
                    
    useEffect(()=> {
        setTypist(true)
    },[typist])

   useEffect(() => {setHome('solutions'); window.scrollTo(0, 0)}) 
   return (
        <>
            <div className="ti-container">
                <h3 className="ti-title">Tecnologías de la información</h3>
                <p className="ti-desc">
                    {TypistDraw()}
                </p>
            </div>
            <div className="ti-detail">
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="ti-card"
                                style={{backgroundImage:`url(${redesURL})`}}>
                                <h5 className="ti-title">REDES</h5>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <RedesCard />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="ti-card"
                                style={{backgroundImage:`url(${monitoreoURL})`}}>
                                <h5 className="ti-title">MONITOREO DE MEDIOS</h5>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <MonitoreoCard />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="ti-card"
                                style={{backgroundImage:`url(${analiticaURL})`}}>
                                <h5 className="ti-title">ANALÍTICA DE DATOS</h5>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <AnaliticaCard />
                        </div>
                    </div>
                </div>
                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <div className="ti-card"
                                style={{backgroundImage:`url(${servidoresURL})`}}>
                                <h5 className="ti-title">SERVIDORES</h5>
                            </div>
                        </div>
                        <div className="flip-card-back">
                            <ServidoresCard />
                        </div>
                    </div>
                </div>
            </div>
            <ProductsFooter />
        </>
    )
}

export default Ti
