import React, { Component } from 'react'
import HeroVideo from './video/HeroVideo';
import Solutions from './solutions/Solutions';
import ProductsHome from './products/ProductsHome';
import Us from './us/Us';
import Clients from './clients/Clients';
import Contact from './contact/Contact';
import Footer from './footer/Footer';

export default class Home extends Component {
    componentDidMount (){
        this.props.setHome('home')
        let el = document.getElementById(window.location.href.split('#').pop())
        if(el === null) return
        window.scrollTo(el.offsetLeft, el.offsetTop)
    }
    render() {
        return (
            <>
                <HeroVideo />
                <Solutions/>
                <ProductsHome />
                <Us />
                <Clients />
                <Contact />
                <Footer />
            </>
        )
    }
}
