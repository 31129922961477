import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import fire from '../../helpers/firebase';

export const Register = ({ history }) => {

    const url = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/blog%2Fdefault.png?alt=media&token=f56d3d33-4ff1-4b9b-a085-335bb7444e91'

    const [data, setData] = useState({})
    const [dataError, setDataError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [serverError, setServerError] = useState(false)

    const handleInput = e => {setData({...data, [e.target.name]:e.target.value})}
    const handleSend = () => {
        if(!data || !data.email || !data.password) return setDataError(true)
        setDataError(false)
        let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if(!rex.test(String(data.email).toLowerCase())) return setEmailError(true)
        setEmailError(false)
        if(data.password !== data.repeatPassword) return setPasswordError(true)
        setPasswordError(false)

        fire.auth().createUserWithEmailAndPassword(data.email, data.password)
            .then(res => {
                localStorage.setItem('user', JSON.stringify({
                    uid: res.user.uid,
                    email: res.user.email,
                    name: data.name,
                    position: data.position,
                    profilePic: url
                }))
                fire.firestore().collection('users').doc(res.user.uid).set({
                    email: res.user.email,
                    name: data.name,
                    position: data.position,
                    posts: [],
                    profilePic: url
                })
            })
            .then(history.push('/blog/manager'))
            .catch(err => {
                setServerError(true)
            })
    }

    return (
        <div className='blog-container login-bg'>
            <div className='login-container'>
                <h2>Registro de usuario</h2>
                <p>Nombre y primer apellido</p>
                <input type="text" name="name" value={data.name} onChange={handleInput}/>
                <p>Puesto / escolaridad</p>
                <input type="text" name="position" value={data.position} onChange={handleInput}/>
                <p>Correo</p>
                <input type="text" name="email" value={data.email} onChange={handleInput}/>
                <p>Contraseña</p>
                <input type="password" name="password" value={data.password} onChange={handleInput}/>
                <p>Repite tu contraseña</p>
                <input type="password" name="repeatPassword" value={data.repeatPassword} onChange={handleInput}/>
                <div className='login-button'>
                    <button onClick={handleSend}>Enviar</button>
                </div>
                <div style={{marginTop: '1rem'}}>
                    <Link to='/blog/login'>Regresar</Link>
                </div>
                {
                    dataError ?
                        <div style={{marginTop: '1rem', color: 'red'}}> 
                            Llena todos los campos
                        </div> 
                    : emailError ? 
                        <div style={{marginTop: '1rem', color: 'red'}}> 
                            Email incorrecto
                        </div> 
                    : passwordError ?
                        <div style={{marginTop: '1rem', color: 'red'}}> 
                            Las contraseñas no coinciden
                        </div> 
                    : serverError ?
                        <div style={{marginTop: '1rem', color: 'red'}}> 
                            Ocurrió un problema en el servidor, ¿la cuenta ya existe?
                        </div> 
                    :
                        null
                }
            </div>
        </div>
    )
}
