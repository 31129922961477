import React from 'react'
import './navbar.css'
import Media from 'react-media'
import Scrollspy from 'react-scrollspy'
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom'

const logo = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Flogo.png?alt=media&token=58d6e593-606d-4904-80f2-895137b26fe6'
const studioLogo = '/img/design/logo.png'

const Navbar = ({home}) => {
    return (
        <div>
            <header id="navheader">
                <nav>
                    <div className='logo-container'>
                        {home === 'neostudio' && <div className='neobase-hover'></div>}
                        <Link to="/"><img src={home === 'neostudio' ? studioLogo : logo} alt="Neobase Logo"/></Link>
                    </div>
                </nav>
                <Media query="(max-width: 855px)">
                    {matches =>
                        matches ? (
                            <Sidebar/>
                        ) : (
                        <>
                            {home === 'home' ? 
                                <Scrollspy items={['home', 'solutions-home', 'products-home', 'us', 'clients', 'blog', 'contact']} currentClassName="is-current" className="nav-scroll">
                                    <li><a href="#home">Inicio</a></li>
                                    <li><a href="#solutions-home">Soluciones</a></li>
                                    <li><a href="#products-home">Productos</a></li>
                                    <li><a href="#us">Nosotros</a></li>
                                    <li><a href="#clients">Clientes</a></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><a href="#contact">Contacto</a></li>
                                </Scrollspy> 
                            : home === 'products' ?
                                <div className="nav-scroll">
                                    <li><Link to="/">Inicio</Link></li>
                                    <li><Link to="/#solutions-home">Soluciones</Link></li>
                                    <li className="is-current"><Link to="/#products-home">Productos</Link></li>
                                    <li><Link to="/#us">Nosotos</Link></li>
                                    <li><Link to="/#clients">Clientes</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/#contact">Contacto</Link></li>
                                </div> 
                            : home === 'blog' ?
                                <div className="nav-scroll">
                                    <li><Link to="/">Inicio</Link></li>
                                    <li><Link to="/#solutions-home">Soluciones</Link></li>
                                    <li><Link to="/#products-home">Productos</Link></li>
                                    <li><Link to="/#us">Nosotos</Link></li>
                                    <li><Link to="/#clients">Clientes</Link></li>
                                    <li className="is-current"><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/#contact">Contacto</Link></li>
                                </div>
                            :
                                <div className="nav-scroll">
                                    <li><Link to="/">Inicio</Link></li>
                                    <li className="is-current"><Link to="/#solutions-home">Soluciones</Link></li>
                                    <li><Link to="/#products-home">Productos</Link></li>
                                    <li><Link to="/#us">Nosotos</Link></li>
                                    <li><Link to="/#clients">Clientes</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                    <li><Link to="/#contact">Contacto</Link></li>
                                </div>
                            }
                        </>
                        )
                    }
                </Media> 
            </header>
        </div>
    )
}

export default Navbar
