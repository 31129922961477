import React from 'react'
import './footer.css'
const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-left">
                <img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Flogo.png?alt=media&token=58d6e593-606d-4904-80f2-895137b26fe6" alt="neobase logo"/>
            </div>
            <div className="footer-right">
                <p>©{new Date().getFullYear()} Neobase. Todos los derechos reservados.</p>
                {/* <a href="#policy">Aviso de privacidad</a> */}
            </div>
        </div>
    )
}

export default Footer
