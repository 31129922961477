import React from 'react'
import { Link } from 'react-router-dom'
import './solutions.css'

const sw = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fsw.png?alt=media&token=acc3f968-dc65-4058-bea2-2c858006f401'
const ti = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fti.png?alt=media&token=cbf84c28-bb17-46a3-a9c2-d58c699d4329'
const ns = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fsolutions%2Fstudio.png?alt=media&token=440647e6-dfe9-4949-b560-18ba1550f626'
const Solutions = () => {
    return (
        <div id="solutions-home" className="solutions-container">
            <h2 className="solutions-title">SOLUCIONES</h2>
            <div className="solutions-icons">
            <figure style={{backgroundColor:"#E43D2F"}} className="solution">
                <figcaption>
                    <p>DESARROLLO DE SOFTWARE</p>
                    <small>Leer más</small>
                </figcaption>
                <Link to="/fabrica-software"><img src={sw} alt="software factory" /></Link>
            </figure>
            <figure style={{backgroundColor:"#199CDE"}} className="solution">
                <figcaption>
                    <p style={{fontSize:"15px"}}>TECNOLOGÍAS DE LA INFORMACIÓN</p>
                    <small>Leer más</small>
                </figcaption>
                <Link to="/ti"><img src={ti} alt="TI" /></Link>
            </figure>
            <figure style={{backgroundColor:"#19AE42"}} className="solution">
                <figcaption>
                    <p>NEOSTUDIO DISEÑO, SOCIAL MEDIA Y MKT</p>
                    <small>Leer más</small>
                </figcaption>
                <Link to="/neostudio"><img src={ns} alt="neostudio" /></Link>
            </figure>
            </div>
        </div>
    )
}

export default Solutions