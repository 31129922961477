import React from 'react'
import './MonitoreoCard.css'

const MonitoreoCard = () => {
  return (
    <>
      <div className="monitoreo-card-container">
        <div className="title-container">
          <h3>SOLUCIONES</h3>
          <h4>Monitoreo de Medios y Sistemas</h4>
        </div>
        <p>
          <strong>Monitoreo de contenido de medios digitales en internet</strong>: 
          Monitoreo de opiniones, palabras clave, nombres, 
          sentimiento, posicionamiento en centros de noticias y blogs en 
          internet.
        </p>
        <p>
          <strong>Monitoreo de redes sociales</strong>: 
          Monitores de alcance, opiniones, nombres, palabras clave, 
          sentimiento, posicionamiento en redes sociales (Facebook, 
          Twitter, Instagram, etc.)
        </p>
        <p>
          <strong>Monitores de infraestructura y sistemas</strong>: 
          Monitoreo de servidores, servicios de Windows, Linux, iOS, 
          aires acondicionados, iluminación e infraestructura en general.
        </p>
        <p>
          <strong>Monitoreo de spots de radio y televisión</strong>: 
          Monitoreo de spots, comerciales, fragmentos de media (video/audio) 
          en radio y televisión en tiempo real.
        </p>
      </div>
    </>
  )
}

export default MonitoreoCard