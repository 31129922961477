import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import fire from '../../helpers/firebase'

export const MyPosts = ({setHome}) => {

    const [posts,setPosts] = useState([])
    const [loading,setLoading] = useState(true)

    let getPosts = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        fire.firestore().collection('users').doc(user.uid).get()
            .then(snapshot => {
                setLoading(false)
                setPosts(snapshot.data().posts)
            })
            .catch(err => setLoading(false))
    }

    useEffect(() => {
        getPosts()
        setHome('blog')
    },[])

    return (
        <div className='blog-container'>
            <div className='sub-nav'>
                <h2>Mis posts</h2>
                <div className='sub-nav-buttons'>
                    <Link to='/blog/manager'><p style={{backgroundColor: '#039DDE'}}>Nuevo post</p></Link>
                    <Link to='/blog/manager/profile'><p style={{backgroundColor:'#039DDE'}}>Mi perfil</p></Link>
                </div>
            </div>
            <table className='my-posts-table'>
                <thead>
                    <tr>
                        <th className='table-title'>Post</th>
                        <th className='editor'>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? 
                            <tr>
                                <td>Cargando...</td>
                                <td></td>
                            </tr>
                        : posts.length ? 
                            posts.map(title => 
                                <tr key={title}>
                                    <td className='table-title'><Link to={`/blog/${title.split(' ').join('_')}`}>{title}</Link></td>
                                    <td className='editor'><Link to={`/blog/edit/${title.split(' ').join('_')}`}>Editar</Link></td>
                                </tr>    
                            )
                        :
                            <tr>
                                <td>No tienes posts</td>
                                <td></td>
                            </tr>
                    }     
                </tbody>
            </table>
        </div>
    )
}
