import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import fire from '../../helpers/firebase'
import firebase from 'firebase/app'
import { Line } from 'rc-progress'
import { Editor } from './Editor';

export const BlogManager = ({setHome, match, history}) => {

    const [data, setData] = useState({})
    const [joditContent, setJoditContent] = useState('')
    const [imageFile, setImageFile]= useState('')
    const [percent, setPercent] = useState(0)
    const [missingFields, setMissingFields] = useState(false)
    const [posted, setPosted] = useState(false)
    const [overwriteError, setOverwriteError] = useState(false)
    const [serverError, setServerError] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [loading, setLoading] = useState(false)

    const uploadCover = e => {
        let file = e.target.files[0]
        setImageFile(file)
    }

    const handleCoverUpload = () => {
        if(imageFile === '') return
        let uploadTask = fire.storage().ref(`/blog/${imageFile.name}`).put(imageFile)
        uploadTask.on('state_changed',
            snapshot => setPercent((snapshot.totalBytes * 100) / snapshot.bytesTransferred),
            err => alert('Ocurrió un error al subir la imagen, intenta nuevamente'),
            () => {
                fire.storage().ref(`/blog/${imageFile.name}`).getDownloadURL()
                .then( firebaseUrl => setData({...data, cover: firebaseUrl}))
            }
        )
    }
    
    const submitPost = () => {
        window.scrollTo(0, document.body.scrollHeight)
        if(!data.cover || !data.title || !joditContent) return setMissingFields(true)
        setMissingFields(false)
        setLoading(true)
        let user = JSON.parse(localStorage.getItem('user'))
        user.posts.push(data.title)
        let postConcentrated = {
            ...data,
            content: joditContent,
            authorData: user,
            active: true,
            date: new Date()
        }
        fire.firestore().collection('posts').doc(data.title).get()
            .then(res => {
                if(res.exists){
                    setLoading(false)
                    return setOverwriteError(true)
                }
                setOverwriteError(false)
                fire.firestore().collection('posts').doc(data.title).set({
                    ...postConcentrated
                })
                .then( success => {
                    fire.firestore().collection('users').doc(user.uid).update({
                        posts: firebase.firestore.FieldValue.arrayUnion(data.title)
                    })
                    localStorage.setItem('user', JSON.stringify({...user}))
                    setJoditContent('')
                    setData({
                        title: '',
                        shortDescription: ''
                    })
                    setPercent(0)
                    setImageFile('')
                    setLoading(false)
                    setPosted(true)
                    setTimeout(() => {
                        setPosted(false)
                    },3000)
                })
            })
    }
        
    const fetchPost = () => {
        if(match.params.post){
            return fire.firestore().collection('posts').doc(match.params.post.split('_').join(' ')).get()
                .then(snap => {
                    let fetchedData = snap.data()
                    setJoditContent(fetchedData.content)
                    setData({
                        ...data, 
                        cover: fetchedData.cover, 
                        title: fetchedData.title,
                        active: fetchedData.active,
                        shortDescription: fetchedData.shortDescription,
                        date: fetchedData.date
                    })
                })
        }
        return
    }

    const updatePost = () => {
        window.scrollTo(0, document.body.scrollHeight)
        if(!data.cover || !data.title || !joditContent) return setMissingFields(true)
        let user = JSON.parse(localStorage.getItem('user'))
        setMissingFields(false)
        setLoading(true)
        let postConcentrated = {
            ...data,
            content: joditContent,
            authorData: user,
            active: true,
            dateModified: new Date()
        }
        fire.firestore().collection('posts').doc(match.params.post.split('_').join(' ')).get()
            .then(res => {
                if(res.exists){
                    if(data.title !== match.params.post.split('_').join(' ')){
                        fire.firestore().collection('posts').doc(match.params.post.split('_').join(' ')).update({active: false})
                        fire.firestore().collection('users').doc(user.uid).get()
                        .then(res => {
                            let userData = res.data()
                            let newPostArray = userData.posts.map(post => post === match.params.post.split('_').join(' ') ? data.title : post )
                            fire.firestore().collection('users').doc(user.uid).update({
                                posts: newPostArray
                            })
                            localStorage.setItem('user', JSON.stringify({...userData, posts: newPostArray}))
                        })
                    }
                    fire.firestore().collection('posts').doc(data.title).set({
                        ...postConcentrated
                    })
                    .then( success => {
                        setLoading(false)
                        setUpdated(true)
                        setTimeout(() => {
                            setUpdated(false)
                            history.push(`/blog/edit/${data.title.split(' ').join('_')}`)
                        },3000)
                    })
                }
            })
            .catch(err => {
                setLoading(false)
                setServerError(true)
            })
    }

    const deletePost = () => {
        fire.firestore().collection('posts').doc(data.title).get()
            .then(res => {
                if(res.exists){
                    if(data.active){
                        fire.firestore().collection('posts').doc(data.title).update({
                            active: false
                        })
                    }
                    else{
                        fire.firestore().collection('posts').doc(data.title).update({
                            active: true
                        })
                    }
                    fetchPost()
                }
            })
    }

    useEffect(() => {
        fetchPost()
    }, [])

    useEffect(() => {setHome('blog'); window.scrollTo(0, 0)}, [setHome])
    
    return (
        <div className='blog-container'>
            <div className='sub-nav'>
                <h2>Nuevo post</h2>
                <div className='sub-nav-buttons'>
                    <Link to='/blog/manager/my-posts'><p>Mis posts</p></Link>
                    <Link to='/blog/manager/profile'><p style={{backgroundColor:'#039DDE'}}>Mi perfil</p></Link>
                </div>
            </div>
            <div className='blog-manager'>
                <div style={data.cover ? {backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${data.cover})`} : null} className='image-input'>
                    <p>Imagen de portada</p>
                    <div className='file-input'>
                        <input onChange={uploadCover} type="file" name="cover" accept="image/*"/>
                    </div>
                    <button onClick={handleCoverUpload}>Subir imagen</button>
                    <Line percent={percent} strokeLinecap='square' strokeWidth='1'trailWidth='1'  trailColor='#D3D3D3'/>
                </div>
                <div className='title'>
                    <p>Título</p>
                    <input onChange={e => setData({...data, [e.target.name]: e.target.value})} value={data.title} type="text" name="title"/>
                </div>
                <div className='title'>
                    <p>Extracto (Texto corto que describe a todo el post)</p>
                    <textarea rows="2" cols="50" onChange={e => setData({...data, [e.target.name]: e.target.value})} value={data.shortDescription} type="text" name="shortDescription"/>
                </div>
                <div className='content'>
                    <p>Contenido</p>
                    <Editor joditContent={joditContent} setJoditContent={setJoditContent}/>
                </div>
                {
                    loading ?
                        <div>
                            <p style={{color: 'green'}}>Cargando...</p>
                        </div>
                    :
                    missingFields ?
                        <div>
                            <p style={{color: 'red'}}>Debes llenar todos los campos (Imagen, título y contenido)</p>
                        </div>
                    :
                    overwriteError ?
                        <div>
                            <p style={{color: 'red'}}>¡Ups! Ya existe un post con ese título</p>
                        </div>
                    :
                    serverError ?
                        <div>
                            <p style={{color: 'red'}}>Ocurrió un error en el servidor, intenta nuevamente</p>
                        </div>
                    :
                    posted ?
                        <div>
                            <p>Publicado correctamente, <Link to={`/blog/${data.title.split(' ').join('_')}`}>Ir al post</Link></p>
                        </div>
                    :
                    updated ?
                        <div>
                            <p>Actualziado correctamente</p>
                        </div>
                    :
                    match.params.post ? 
                        <div>
                            <p><Link to={`/blog/${match.params.post.split('_').join(' ')}`}>Ir al post</Link></p>
                        </div>
                    :
                        null
                }
                {
                    match.params.post ?
                        <div className='new-post-buttons'>
                            {data.active ? <p onMouseUp={updatePost}>Actualizar</p> : null}
                            <p className={data.active ? 'post-delete' :null} onMouseUp={deletePost}>{data.active ? 'Ocultar publicación' : 'Publicar'}</p>
                            <Link to='/blog'><p className='post-cancel'>Cancelar</p></Link>
                        </div>
                    :
                        <div className='new-post-buttons'>
                            <p onMouseUp={submitPost}>Publicar</p>
                            <Link to='/blog'><p className='post-cancel'>Cancelar</p></Link>
                        </div>
                }
            </div>
        </div>
    )
}
