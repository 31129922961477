import React from 'react'
import CarouselSlider from 'react-carousel-slider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const Carousel = () => {

    const data = [
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Ffss.png?alt=media&token=a3bebd30-906f-4238-bdaf-018d19735223',
            name: "Diseño, desarrollo de una plataforma de seguimiento de tickets de servicio",
            des: <>Sistema de seguimiento de solicitudes de servicios y trabajos realizados por contratistas, SLAs, evidencia, actores.<br/>Generación de reportes e informes.</>
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Fizzi-client.png?alt=media&token=744d86ae-cc0f-4d1d-85b3-850ec9330edb',
            name: "Diseño, instalación de red y configuración de servidores",
            des: "Diseño e implementación de red para datos, configuración de servidores no lineales para VoIP."
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Fneology.png?alt=media&token=26afe85a-569f-4ab3-beae-ea56b71a917c',
            name: "Plataforma Web",
            des: <>Diseño y desarrollo de la plataforma web de la empresa, mejorando la experiencia de usuario.</>
        },
        {
            imgSrc: '/img/ine.png',
            name: "Renovación tecnológica",
            des: <>Renovación tecnológica y soporte técnico de 147 Centros de Monitoreo a nivel nacional. Atención remota y en sitio.</>
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Fams.png?alt=media&token=a721dde0-c738-4357-b7c7-e2fe2d50492b',
            name: "Plataforma de monitoreo de unidades",
            des: <>Diseño e implementación de plataforma de monitoreo mediante cámaras de video para autobuses y camiones del estado de Austin.</>
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Freintegradores.png?alt=media&token=1d40fb30-9f88-4b6c-b84d-5b6ac209c383',
            name: "Desarrollo de una plataforma de transparencia financiera",
            des: <>Plataforma web y app para monitorear y dar seguimiento a organizaciones y donatarios en la aplicación y vía de los recursos.</>
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2Frtc-client.png?alt=media&token=5f309f77-9761-4222-93a4-e083157d1198',
            name: "Diseño y desarrollo del sistema integral de monitoreo de RTC",
            des: <>Sistema Integral de monitoreo de spots de radio y televisión a nivel nacional.<br/>Integración con motores de huella acústica.<br/> Generación de reportes y cruces.</>
        },
        {
            imgSrc: 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fclients%2FnewEra-client.png?alt=media&token=ec731b36-7e8d-4c0e-bdae-6de554dcac2d',
            name: "Desarrollo e integración del APP para punto de venta New Era",
            des: <>APP para la administración de ventas en sitio a gran escala <br/> Reconocimiento de imágenes <br/> Lector de códigos de barras y QR <br/> Carrito de compras, Reportes e informes.</>
        },
    ]

    let itemsStyle = {
        padding: "0px",
        background: "white",
        margin:"0 30px",
        borderRadius: "4px"
    }

    let imgStyle = {
        height: "50%",
        boxShadow: "1px 1px 1px 1px #9E9E9E"
    }
    
    let textBoxStyle = {
        width: "100%",
        top: "12rem",
        color: "black",
        background: "transparent",
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: '1rem'
    }

    let textBoxStyle2 = {
        width: "100%",
        top: "16rem",
        color: "black",
        background: "transparent",
        fontSize: "1rem",
        lineHeight: '1rem',
        textAlign: "justify"
    }

    let btnStyle = {
        display: "inline-block",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
        fontSize: "36px"
    }

    let btnWrapperStyle = {
        height: "50px",
        width: "50px"
    }

    let rBtnCpnt = (<div style = {btnWrapperStyle} >
        <FontAwesomeIcon style = {btnStyle} icon={faChevronRight}/>
    </div>)

    let lBtnCpnt = (<div style = {btnWrapperStyle} >
        <FontAwesomeIcon style = {btnStyle} icon={faChevronLeft}/>
    </div>)

    let clients = data.map((item, index) => 
            <div key = {index} >
                <img style = {imgStyle} src = {item.imgSrc} alt="client img"></img>
                <p style = {textBoxStyle} >{item.name}</p>
                <p style = {textBoxStyle2} >{item.des}</p>
            </div>
        )

    let clientsCard = (<CarouselSlider 
        sliderBoxStyle = {{height: "400px", width: "90%", background: "transparent", position:"relative"}} 
        accEle = {{dots: false}}
        slideCpnts = {clients} 
        itemsStyle = {itemsStyle} 
        buttonSetting = {{placeOn: 'middle-outside'}}
        rBtnCpnt = {rBtnCpnt}
        lBtnCpnt = {lBtnCpnt}
    />)


    return (
        <div style={{position: "relative"}}>
            {clientsCard}
        </div>
    )
}

export default Carousel
