import React from 'react'
import './herovideo.css'

const video = "https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2FNeobase%202020.mp4?alt=media&token=024c46dc-c1de-420d-8ba0-08c0bac4af83"

const HeroVideo = () => {
    return (
        <div className="hero-video">
            <div id="home" style={{height:'64px'}}></div>
            <div className="video-container">
                <p className="hero-text">Developing Life</p>
                <video loop muted autoPlay id="myVideo">
                    <source src={video} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default HeroVideo