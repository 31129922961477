import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import fire from '../../helpers/firebase';

export const Login = ({history}) => {

    const [data, setData] = useState({})
    const [dataError, setDataError] = useState(false)

    const handleInput = e => {setData({...data, [e.target.name]:e.target.value})}
    const handleSend = () => {
        if(!data || !data.email || !data.password) return setDataError(true)
       fire.auth().signInWithEmailAndPassword(data.email, data.password)
        .then(res => {
            fire.firestore().collection('users').doc(res.user.uid).get()
                .then(snap => {
                    let data = snap.data()
                    localStorage.setItem('user', JSON.stringify({
                        uid: res.user.uid,
                        email: res.user.email,
                        name: data.name,
                        position: data.position,
                        profilePic: data.profilePic,
                        posts: data.posts
                    }))
                    history.push('/blog/manager')
                })
        })
        .catch(err => setDataError(true))
    }

    return (
        <div className='blog-container login-bg'>
            <div className='login-container'>
                <h2>Iniciar sesión</h2>
                <p>Usuario</p>
                <input type="text" name="email" value={data.email} onChange={handleInput}/>
                <p>Contraseña</p>
                <input type="password" name="password" value={data.password}  
                    onKeyDown={e => {
                        if(e.key === 'Enter') return handleSend()
                    }
                    }
                    onChange={handleInput}
                />
                <div className='login-button'>
                    <button onClick={handleSend}>Enviar</button>
                </div>
                <div style={{marginTop: '1rem'}}>
                    <Link to='/blog/register'>Registrarme</Link>
                </div>
                {
                    dataError ?
                        <div style={{marginTop: '1rem', color: 'red'}}> 
                            Usuario o contraseña incorrectos
                        </div> 
                    :
                        null
                }
            </div>
        </div>
    )
}
