import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: "AIzaSyBAL7alc-dKK9HLFjbfzYxQauMMXU69yJc",
    authDomain: "neobase-web.firebaseapp.com",
    databaseURL: "https://neobase-web.firebaseio.com",
    projectId: "neobase-web",
    storageBucket: "neobase-web.appspot.com",
    messagingSenderId: "1069174934728",
    appId: "1:1069174934728:web:1d66b9e41b7f290afd69fd"
  }

const fire = firebase.initializeApp(firebaseConfig)

export default fire