import React, { useState, useEffect } from 'react'
import './blog.css'
import { Link } from 'react-router-dom';
import fire from '../../helpers/firebase';
import HashLoader from 'react-spinners/HashLoader'

export const Post = ({props, setHome}) => {

    const [post, setPost] = useState({})
    const [author, setAuthor] = useState({})
    const [loadAgain, setLoadAgain] = useState(true)

    const fetchPost = () => {
        let postLoc = props.match.params.post.split('_').join(' ')
        fire.firestore().collection('posts').doc(postLoc).get()
            .then(res => {
                if(res.data) {
                    fire.firestore().collection('users').doc(res.data().authorData.uid).get()
                        .then(res => setAuthor(res.data()))
                    return setPost(res.data())
                }
                return setLoadAgain(true)
            })
    }

    useEffect(() => {
        fetchPost()
    }, [])
    
    useEffect(() => {setHome('blog'); window.scrollTo(0, 0)})

    return (
        <div className='blog-container'>
            {
                post.title ? 
                <article>
                    <div style={{backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.5)), url(${post.cover})`}} className='post-title-container'>
                        <h1>{post.title}</h1>
                        <div style={{backgroundImage:`url(${author.profilePic})`}} className='author-image'></div>
                        <p>Por: <strong>{author.name}</strong></p>
                        <span>{author.position}</span>
                        <small>{new Date(post.date.seconds * 1000 + post.date.nanoseconds / 1000, ).toLocaleDateString()}</small>
                    </div>
                    <div className='bread-crumb'>
                        <Link to='/blog'>Blog</Link>&nbsp;<span>/</span>&nbsp;<span> {post.title}</span>
                    </div>
                    <div className='post-text-container'>
                        <div dangerouslySetInnerHTML={{__html: post.content}} className='post-text'></div>
                    </div>
                </article>
                :
                <div style={{position: 'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)',  display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <HashLoader size={120} color={"#00A3E8"} loading/>
                    {loadAgain && <p style={{marginTop: '1rem'}}>¿Tardando mucho? <a href={`${window.location.href}`}>Da click aquí</a></p>}
                </div>
            }
        </div>
    )
}
