import React, { useState } from 'react'
import './objectives.css'

const Objectives = () => {
    const [ objective, setObjective ] = useState('A')
    return (
        <>
            <h2 className="objectives-title">OBJETIVOS</h2>
            <div className="objectives-container">
                <div onMouseOver={() => setObjective('A')} id="A" className={objective === 'A' ?  "objective ob-a active" : "objective ob-a"}>A</div>
                <span className="divider"></span>
                <div onMouseOver={() => setObjective('B')} id="B" className={objective === 'B' ?  "objective ob-b active" : "objective ob-b"}>B</div>
                <span className="divider"></span>
                <div onMouseOver={() => setObjective('C')} id="C" className={objective === 'C' ?  "objective ob-c active" : "objective ob-c"}>C</div>
                <span className="divider"></span>
                <div onMouseOver={() => setObjective('D')} id="D" className={objective === 'D' ?  "objective ob-d active" : "objective ob-d"}>D</div>
                <span className="divider"></span>
                <div onMouseOver={() => setObjective('E')} id="E" className={objective === 'E' ?  "objective ob-e active" : "objective ob-e"}>E</div>
            </div>
            <div 
                style={objective === 'A' ? {backgroundColor:"black"} :
                    objective === 'B' ? {backgroundColor:"#E43D2F"} :
                    objective === 'C' ? {backgroundColor:"#199CDE"} :
                    objective === 'D' ? {backgroundColor:"#19AE42"} :
                    objective === 'E' ? {backgroundColor:"black"} : {backgroundColor: 'black'}} 
                className="objectives-content">

                    {objective === 'A' ? <p><b>Objetivo A</b><br/>Complementar y fortalecer nuestro equipo multidisciplinario para innovar e integrar tecnologías y procesos con el mundo real.</p> :
                        objective === 'B' ? <p><b>Objetivo B</b><br/>Proveer soluciones integrales especializadas que permitan optimizar el manejo de la información, facilitando la comunicación, colaboración y coordinación entre las diferentes áreas que conforman a la empresa, ajustándonos a las necesidades de cada cliente.</p> :
                        objective === 'C' ? <p><b>Objetivo C</b><br/>Desarrollar sistemas y aplicaciones a la medida, con tecnología de punta, para apoyar a nuestros clientes a solventar sus necesidades y requerimientos permitiéndoles operar de una manera sencilla y eficiente.</p> :
                        objective === 'D' ? <p><b>Objetivo D</b><br/>Ofrecer la integración de soluciones y tecnologías de vanguardia que satisfagan las necesidades del mercado y permita a nuestros clientes ser altamente competitivos e incrementar su rentabilidad (bajar costos, incrementar ventas, procesar con mayor velocidad)</p> :
                        objective === 'E' ? <p><b>Objetivo E</b><br/>Apoyar en la integración y culminación de proyectos en cualquiera de sus etapas en que se encuentre, alineados a las mejores prácticas en implementación de proyectos, brindando siempre un servicio integral, comprometido y de calidad.</p> :
                        'Objetivo A'}

            </div>
        </>
    )
}

export default Objectives
