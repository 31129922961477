import React from 'react'
import 'jodit';
import 'jodit/build/jodit.min.css';
import JoditEditor from "jodit-react";

export const Editor = ({joditContent, setJoditContent}) => {

    const config = {
        buttons: [ "bold", "italic", "underline", "strikethrough", "underline", "font", "fontsize", "paragraph", "|", "ul", "ol", "hr", "|", "left", "center", "right", "justify", "|", "link", "image", "redo"],
        uploader: { insertImageAsBase64URI: true },
        removeButtons: ["brush", "file"],
        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: false,
        height: 600
    }

    const handleChange = newContent => setJoditContent(newContent)

    return (
        <div className='text-editor'>
            <JoditEditor
                value={joditContent}
                config={config}
                onBlur={handleChange}
            />
        </div>
    )
}
