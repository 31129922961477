import React, { useEffect } from 'react'
import './furniture.css'
import ProductsFooter from '../footer/ProductsFooter';

const Furniture = ({setHome}) => {
  useEffect(() => {setHome('products'); window.scrollTo(0, 0)}) 
  return (
    <>
      <div className="furniture-container">
        <h3 className="furniture-title">MOBILIARIO URBANO</h3>
        <h4 className="furniture-subtitle">Autosustentable</h4>
        <p className="furniture-desc">
          Muebles urbanos para avenidas, centros comerciales o <br/>
          parques. Autosustentables y adaptados tecnologóciamente para <br/>
          brindar servicios de wifi, contenido en streaming, publicidad en <br/>
          video, eventos deportivos, etc.
        </p>
        <ul>
          <li>Estructura 85% con materiales reciclados.</li>
          <li>Páneles solares.</li>
          <li>Pantalla LED de bajo consumo eléctrico.</li>
          <li>Centros de carga para celulares.</li>
          <li>10 años de garantía en estructura.</li>
          <li>Servicio de wifi.</li>
          <li>Contenedores de pilas.</li>
        </ul>
      </div>
      <div className="furniture-detail">
        <div className="furniture-detail-left">
          <h4 className="furniture-detail-title">ECO TREE</h4>
          <p>
            Árboles o palmeras tecnológicas autosustenables, ideales para <br/>
            hoteles, escuelas y centros comerciales donde se requiera <br/>
            conectividad a internet, facilidad para cargar la batería de los <br/>
            celulares y acceso a contenidos en streaming.
          </p>
          <ul>
            <li>Estructura 85% con materiales reciclados.</li>
            <li>Páneles solares.</li>
            <li>Pantalla LED de bajo consumo eléctrico.</li>
            <li>Centros de carga para celulares.</li>
            <li>10 años de garantía en estructura.</li>
            <li>Servicio de wifi.</li>
            <li>Contenedores de pilas.</li>
          </ul>
        </div>
        <div className="furniture-detail-right">
          <p>
            Desarrollo de estructuras <br/>
            a las necesidades y <br/>
            gusto de los clientes.
          </p>
        </div>
      </div>
      <ProductsFooter />
    </>
  )
}

export default Furniture