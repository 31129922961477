import React from 'react'
import './AnaliticaCard.css'

const AnaliticaCard = () => {
  return (
    <>
      <div className="analitica-card-container">
        <div className="title-container">
          <h3>SOLUCIONES</h3>
          <h4>Analítica de datos y Deployment</h4>
          <h5>Ciclo de la información</h5>
          <h5 className="gray-title">Soluciones BI & Data Management</h5>
        </div>
        <p className="intro-analitica">
          Las soluciones de Neobase, abarcan el ciclo 
          completo de la información, lo que nos permite 
          ofrecer la mejor y más completa opción a nuestros 
          clientes, en la fase de ciclo que lo requieren:
        </p>
        <p>
          <strong>Data</strong>: todo lo referente al tratamiento de los datos,
          desde su extracción, transformación, limpieza,
          consolidación e integración y almacenamiento hasta
          su puesta a disposición para las áreas de análisis y
          de negocio.
        </p>
        <p>
          <strong>Discovery</strong>: desde análisis estadísticos
          fundamentales, hasta el desarrollo de modelos BI,
          indicadores y modelos predictivos avanzados. Este es
          el motor que genera <i>inteligencia de los datos</i>.
        </p>
        <p>
          <strong>Deployment</strong>: generación y automatización de
          reportes e informes, habilitación de dashboards y
          acceso a visualizaciones avanzadas para las áreas
          directivas y de negocio, que les permite soportar la
          toma de decisiones.
        </p>
      </div>
    </>
  )
}

export default AnaliticaCard