import React from 'react'
import './us.css'
import Objectives from '../objectives/Objectives';

const Us = () => {
    return (
        <div id="us">
            <h2 className="us-title">NOSOTROS</h2>
            <div className="gray-container">
                <p>Somos una empresa con más de 10 años de experiencia en el ámbito de tecnologías de la información, integramos soluciones a la media de hardware con software, contamos con un equipo multidisciplinario, profesional y con amplia experiencia.
                   <br/><br/> Desarrollamos soluciones de software y hardware a la medida de cada necesidad; implementamos proyectos en cualquier etapa en la que se encuentre; contamos con una gran red de contactos que potencializan la culminación de cada idea y de cada proyecto de manera satisfactoria y segura.
                   <br/><br/> Somos partners de empresas líderes en el mercado cómo: SAS, Integraled, Absen, Ricoh, Stirlitz media, Dell y muchas más.</p>
            </div>
            <Objectives />
        </div>
    )
}

export default Us
