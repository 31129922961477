import React, { useState } from 'react';
import './App.css';
import Routes from './Routes';
import Navbar from './components/navbar/Navbar';

function App() {
  const [ home, setHome] = useState('home')
  return (
    <div className="App">
      <Navbar home={home}/>
      <Routes setHome={setHome}/>
    </div>
  );
}

export default App;
