import React, { useEffect, useState } from 'react'
import './blog.css'
import { Link } from 'react-router-dom'
import fire from '../../helpers/firebase'
import HashLoader from 'react-spinners/HashLoader'

export const Blog = ({setHome}) => {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadAgain, setLoadAgain] = useState(false)

    const fetchPosts = () => {
        setLoading(true)
        fire.firestore().collection('posts').get()
            .then(snap => {
                setLoading(false)
                if(snap.docs) return setPosts(snap.docs.map(doc => doc.data()))
                return setLoadAgain(true)
            })
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    useEffect(() => {setHome('blog'); window.scrollTo(0, 0)}) 
    
    return (
        <div className='blog-container'>
            <div className='blog-hero'>
                <h2 className='blog-title'>BLOG</h2>        
            </div>
            <div className='posts-container'>
                {
                    loading ? 
                    <div style={{marginTop: '2rem', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <HashLoader size={120} color={"#00A3E8"} loading/>
                        {loadAgain && <p style={{marginTop: '1rem'}}>¿Tardando mucho? <a href={`${window.location.href}`}>Da click aquí</a></p>}
                    </div>
                : posts.length ? posts.filter(post => post.active).sort((a, b) => b.date - a.date).map(post => 
                        <Link key={post.title} to={{pathname: `/blog/${post.title.split(' ').join('_')}`}}>
                            <div className="post">
                                <img src={post.cover} alt={post.title}/>
                                <p className='post-title'>{post.title}</p>
                                <p className='post-short-description'>{post.shortDescription}</p>
                                <div className='post-stamps'>
                                <p className='post-author'>Por: {post.authorData && post.authorData.name} - <span className='post-date'>{new Date(post.date.seconds * 1000 + post.date.nanoseconds / 1000, ).toLocaleDateString()}</span></p>
                                </div>
                            </div>    
                        </Link>
                    )
                :
                    <div style={{marginTop: '2rem', display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <p style={{marginTop: '1rem'}}>No se han encontrado posts</p>
                    </div>
                }
            </div>
        </div>
    )
}
