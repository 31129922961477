import React from 'react'
import './ProductsFooter.css'

const ProductsFooter = () => {
  return (
    <div className='footer-section-container'>
      <div className="footer-section-left">
        <p>
          DAMOS SOLUCIONES<br/>
          A TU MEDIDA.
        </p>
        <img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Flogo_invertido.png?alt=media&token=56f11117-cbbb-4bca-b393-0b6768107c64" alt="Neobase Logo"/>
      </div>
      <div className="footer-section-middle">
        <img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fisotipo.png?alt=media&token=73ae2057-bb7e-4a0f-bf6f-8063cc3798f2" alt="Neobase isotipo"/>
      </div>
      <div className="footer-section-right">
        <div className='footer-section-email'>
          <span>soporte@neobase.com.mx</span>
          <span>contacto@neobase.com.mx</span>
        </div>
        <span>©{new Date().getFullYear()} Neobase.</span>
        <span>Todos los derechos reservados.</span>
      </div>
    </div>
  )
}

export default ProductsFooter