import React, { useState } from 'react'
import Typist from 'react-typist'
import './contact.css'
import 'react-typist/dist/Typist.css'
import Observer from 'react-intersection-observer'

const { Backspace, Delay } = Typist

const Contact = () => {
    const [ typist, setTypist ] = useState(false)
    
    const TypistDraw = () => 
                    typist ? <Typist>
                        <Delay ms={300}/>
                        <span><b>N</b>ovedosos </span>    
                        <Backspace count={9} delay={1000}/>
                        <span><b>E</b>mprendedores </span>    
                        <Backspace count={13} delay={1000}/>
                        <span><b>O</b>riginales </span>    
                        <Backspace count={10} delay={1000}/>
                        <span><b>B</b>uscadores </span>    
                        <Backspace count={10} delay={1000}/>
                        <span><b>A</b>stutos </span>    
                        <Backspace count={7} delay={1000}/>
                        <span><b>S</b>encillos </span>    
                        <Backspace count={9} delay={1000}/>
                        <span><b>E</b>ntusiastas </span>    
                        <Backspace count={11} delay={1000}/>
                        <span> </span>    
                    </Typist> : null

    return (
        <div id="contact">
             <h2 className="contact-title">CONTÁCTANOS, ESTAMOS PARA AYUDARTE</h2>
             <div className="contact-content">
                 <div className="contact-left">
                        <img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Ffooter%2Fisotipo.png?alt=media&token=7dfa83eb-5f56-4f77-adf7-acefb34cebfe" alt="isotipo"/>
                        <p className="left-part-title">SOMOS</p>
                        <Observer onChange={inView => {inView ? setTypist(true) : setTypist(false)}}>{TypistDraw()}</Observer>
                 </div>
                 <div className="contact-right">
                    <div className="contact-part">
                        <p className='contact-part-title'>SOPORTE TÉCNICO</p>
                        <p >soporte@neobase.com.mx</p>
                    </div>
                    <div className="contact-part">
                        <p className='contact-part-title'>CONTACTO</p>
                        <p >contacto@neobase.com.mx</p>
                    </div>
                    <div className="contact-part">
                        <p className='contact-part-title'>DIRECCIÓN</p>
                        <p >Retorno 27 #2, Colonia Avante <br/> Ciudad de México</p>
                    </div>
                    <div className="contact-social">
                        <a href="https://www.facebook.com/neobasedevelopinglife/" target="_blank" rel='noopener noreferrer'><img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Ffooter%2Ffb-icon.png?alt=media&token=19e255cc-f34d-4022-a32d-a44875cd6f5a" alt="FB"/></a>
                        <a href="https://www.linkedin.com/company/neobase-developing-life/" target="_blank" rel='noopener noreferrer'><img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Ffooter%2Flinkedin-icon.png?alt=media&token=12d43b52-9b0f-4dbd-bea7-845157428c36" alt="IN"/></a>
                        <a href="https://twitter.com/neobasemx" target="_blank" rel='noopener noreferrer'><img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Ffooter%2Ftwitter-icon.png?alt=media&token=5f94da19-aaf6-46a6-a875-9e35bc2d7498" alt="TW"/></a>
                    </div>
                 </div>
             </div>
        </div>
    )
}

export default Contact
