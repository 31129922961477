import React from 'react'
import './ServidoresCard.css'

const ServidoresCard = () => {
  return (
    <>
      <div className="servidores-card-container">
        <div className="title-container">
          <h3>SOLUCIONES</h3>
          <h4>Servidores</h4>
        </div>
        <p>
          <strong>Configuración e instalaciónde servidores</strong>: 
          Venta, instalación y puesta a punto de 
          servidores de digitalización y detección mediante 
          huella acústica. <br/>
          Servicio de mantenimiento, actualización y soporte.
        </p>
        <p>
          <strong>Instalación Site</strong>: 
          Diseño del proyecto, cámara fría, exclusa, racks, sistema 
          contra incendio, piso falso, UPS, servidores, configuración 
          de equipos y red.
        </p>
        <p>
          <strong>Equipo, software y seguridad</strong>: 
          Routers, switch, firewalls, controladores, cable de red 
          todas las categorías.
        </p>
      </div>
    </>
  )
}

export default ServidoresCard