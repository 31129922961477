import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from './components/landing/Home';
import Kiosks from './components/products/kiosks/Kiosks';
import Furniture from './components/products/furniture/Furniture';
import Software from './components/products/software/Software';
import Pantallas from './components/products/pantallas/Pantallas';
import Monitoreo from './components/products/monitoreo/Monitoreo';
import Neostudio from './components/services/neostudio/Neostudio';
import SoftwareFactory from './components/services/software-factory/SoftwareFactory';
import Ti from './components/services/ti/Ti';
import { Blog } from './components/blog/Blog';
import { Post } from './components/blog/Post';
import { Login } from './components/blog/Login';
import { Register } from './components/blog/Register';
import { BlogManager } from './components/blog/BlogManager';
import fire from './helpers/firebase';
import { BlogProfile } from './components/blog/BlogProfile';
import { MyPosts } from './components/blog/MyPosts';


const Routes = ({setHome}) => {

    const [logged, setLogged] = useState(localStorage.getItem('logged'))

    const isAuth = () =>  
        fire.auth().onAuthStateChanged(user => {
            if(user) {
                localStorage.setItem('logged', true)
                return setLogged(true)
            }
            localStorage.setItem('logged', false)
            return setLogged(false)
        })

    useEffect(() => {
        isAuth()
    }, [])

    return (
        // Los componentes se dividirán en Home, Productos y Servicios, vamos a cambiar la navbar de acuerdo a la ruta donde nos encontremos
        <Switch>
            <Route exact path="/" render={(props) => <Home {...props} setHome={setHome} />} />
            <Route path="/kioskos" render={(props) => <Kiosks {...props} setHome={setHome} />}/>
            <Route path="/mobiliario" render={(props) => <Furniture {...props} setHome={setHome} />}/>
            <Route path="/software" render={(props) => <Software {...props} setHome={setHome} />}/>
            <Route path="/pantallas" render={(props) => <Pantallas {...props} setHome={setHome} />}/>
            <Route path="/monitoreo" render={(props) => <Monitoreo {...props} setHome={setHome} />}/>
            <Route path="/neostudio" render={(props) => <Neostudio {...props} setHome={setHome} />}/>
            <Route path="/fabrica-software" render={(props) => <SoftwareFactory {...props} setHome={setHome} />}/>
            <Route path="/ti" render={(props) => <Ti {...props} setHome={setHome} />}/>
            <Route exact path="/blog" render={(props) => <Blog {...props} setHome={setHome} />}/>
            {/*PRIVATE ROUTES */}
            <Route 
                exact path="/blog/login" 
                render={ props => 
                    logged ? <Redirect to={props.location.state ? props.location.state.path : '/blog/manager'}/> 
                    : <Login {...props} setHome={setHome}/>
                }
            />
            <Route 
                exact path="/blog/register" 
                render={ props => 
                    logged ? <Redirect to='/blog/manager'/> 
                    : <Register {...props} setHome={setHome}/>
                }
            />
            <Route 
                exact path="/blog/manager" 
                render={props => 
                    logged ? <BlogManager {...props} setHome={setHome}/> 
                    : <Redirect to={{
                            pathname: '/blog/login', 
                            state: { path: '/blog/manager' }
                        }}/>
                }
            />
            <Route 
                exact path="/blog/manager/profile" 
                render={props => 
                    logged ? <BlogProfile {...props} setHome={setHome}/> 
                    : <Redirect to={{
                        pathname: '/blog/login', 
                        state: { path: '/blog/manager/profile' }
                    }}/>
                }
            />
            <Route 
                exact path="/blog/manager/my-posts" 
                render={props => 
                    logged ? <MyPosts {...props} setHome={setHome}/> 
                    : <Redirect to={{
                        pathname: '/blog/login', 
                        state: { path: '/blog/manager/my-posts' }
                    }}/>
                }
            />
            <Route 
                exact path="/blog/edit/:post" 
                render={props => 
                    logged ? <BlogManager path={"/blog/edit/:post"} {...props} setHome={setHome}/> 
                    : <Redirect to={{
                        pathname: '/blog/login', 
                        state: { path: '/blog/manager/my-posts' }
                    }}/>
                }
            />
            {/*PRIVATE ROUTES END*/}
            <Route path="/blog/:post" render={(props) => <Post props={props} setHome={setHome} />}/>
            <Route render={(props) => <Home {...props} setHome={setHome} />} />
        </Switch>
    )
}

export default Routes
