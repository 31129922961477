import React from 'react'
import './RedesCard.css'

const RedesCard = () => {
  return (
    <>
      <div className="redes-card-container">
        <div className="title-container">
          <h3>SOLUCIONES</h3>
          <h4>Redes</h4>
          <h5>Diseño, optimización e instalación de redes de telecomunicaciones</h5>
        </div>
        <p>
          Diseño y rediseño de enlaces de microonda, redes 
          alámbricas e inalámbricas, cableado estructurado,
          optimización de la señal, rango y potencia.
        </p>
        <p>
          <strong>Site Survey</strong>: 
          Análisis de red, infraestructura, alcance, seguridad, puntos
          ciegos y redistribución de equipo, optimización de la 
          infraestructura de red. Instalación y renovación de equipo
          de red.
        </p>
        <p>
          <strong>Administración, monitoreo y control de redes</strong>: 
          Software para el control, monitoreo y administración de 
          la infraestructura de red, ancho de banda, páginas 
          prohibidas, número de conexiones, tiempos de 
          navegación, publicidad, avisos.
        </p>
      </div>
    </>
  )
}

export default RedesCard