import React, { useState, useEffect } from 'react'
import './SoftwareFactory.css'
import ProductsFooter from '../../products/footer/ProductsFooter';
import Typist from 'react-typist'
import 'react-typist/dist/Typist.css'

const { Backspace, Delay } = Typist

const SoftwareFactory = ({setHome}) => {

    const [ typist, setTypist ] = useState(false)
    
    const TypistDraw = () => 
                    typist ? <Typist avgTypingDelay={50} onTypingDone={() => setTypist(false)}>
                        <Delay ms={300}/>
                        <span>Diseño y desarrollo de aplicaciones para escritorio a la medida.</span>    
                        <Backspace count={28} delay={1000}/>
                        <span>en lenguaje nativo.</span>    
                        <Backspace count={19} delay={1000}/>
                        <span>en la nube.</span>    
                        <Backspace count={40} delay={1000}/>
                        <span>e implementación de bases de datos.</span>    
                        <Backspace count={42} delay={1000}/>
                        <span>Integración de tecnologías mediante software.</span>    
                        <Backspace count={45} delay={1000}/>
                    </Typist> : null
                    
    useEffect(()=> {
        setTypist(true)
    },[typist])

    useEffect(() => {setHome('solutions'); window.scrollTo(0, 0)}) 
    return (
        <>
            <div className="sw-factory-container">
                <h4 className="sw-factory-subtitle">Desarrollo de</h4>
                <h3 className="sw-factory-title"> SOFTWARE</h3>
                <p className="sw-factory-desc">
                    {TypistDraw()}
                </p>
            </div>
            <div className="sw-factory-showcase">
                <div className='showcase-item'>
                    <img src="/img/development/analisis.png" alt="neobase analisis"/>
                    <h2>Análisis</h2>
                    <h3>Entendemos tus retos</h3>
                    <p>
                        Sabemos que tu negocio no es único, nos enfocamos en escucharte con atención para entender los 
                        problemas reales que enfrenta tu empresa
                    </p>
                </div>
                <div className='showcase-item'>
                    <img src="/img/development/diseño.png" alt="neobase diseño"/>
                    <h2>Diseño</h2>
                    <h3>Creamos contigo la solución</h3>
                    <p>
                        Te proponemos soluciones ágiles, confiables y efectivas que se adapten a tu forma de trabajo,
                        presupuesto y tu equipo de colaboradores
                    </p>
                </div>
                <div className='showcase-item'>
                    <img src="/img/development/implementacion.png" alt="neobase implementacion"/>
                    <h2>Implementacion</h2>
                    <h3>Desarrollamos tu sistema</h3>
                    <p>
                        Nuestra plataforma de aplicaciones modulares tiene los componentes que necesitas, 
                        desde gran flexibilidad hasta rápida implementación
                    </p>
                </div>
                <div className='showcase-item'>
                    <img src="/img/development/mantenimiento.png" alt="neobase mantenimiento"/>
                    <h2>Mantenimiento</h2>
                    <h3>Te acompañamos en el camino</h3>
                    <p>
                        Nos encargamos de que tu sistema siempre funcione y se mantenga en línea para tu negocio,
                        siempre con el soporte personalizado para cualquier reto
                    </p>
                </div>
            </div>
            <div className="sw-factory-detail">
                <div className="sw-factory-detail-left">
                    <h4 className="sw-factory-detail-title">
                        DESARROLLO DE SOFTWARE <br/>
                        DE AUTOMATIZACIÓN DE PROCESOS
                    </h4>
                    <p>
                        Automatización de flujos y procesos completos; administrativos, de <br/>
                        operación, industriales, de seguimiento, logística y de alta dirección.
                    </p>
                    <h4 className="sw-factory-detail-title">
                        DISEÑO E IMPLEMENTACIÓN <br/>
                        DE SISTEMAS EN LA NUBE
                    </h4>
                    <p>
                        Integración de sistemas, aplicaciones, bases de datos, sistemas <br/>
                        de monitoreo en la nube, sistemas desarrollados a la medida <br/>
                        del cliete, acceso en cualquier momento desde cualqueier parte.
                    </p>
                    <h4 className="sw-factory-detail-title">
                        INTEGRACIÓN Y AUTOMATIZACIÓN <br/>
                        DE FLUJOS DE TRABAJO
                    </h4>
                    <p>
                        Desarrollos específicos e integración de hardware/equipo para la <br/>
                        automatización de procesos industriales, corporativos o administrativos. <br/>
                        Generando Aplicaciones y Sistemas de Control para optimizar <br/>
                        tiempos y disminuir factores de falla o riesgo.
                    </p>
                </div>
                <div className="sw-factory-detail-right">
                    <p>
                        Fabricamos todo tipo <br/>
                        de software y desarrollo <br/>
                        de apps para dispositivos <br/>
                        móviles (iOS y Android).
                    </p>
                </div>
            </div>
            <ProductsFooter />
        </>
    )
}

export default SoftwareFactory
