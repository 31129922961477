import React, { useEffect } from 'react'
import './monitoreo.css'
import ProductsFooter from '../footer/ProductsFooter';

const Monitoreo = ({setHome}) => {
  useEffect(() => {setHome('products'); window.scrollTo(0, 0)}) 
  return (
    <>
      <div className="monitoreo-container">
        <h3 className="monitoreo-title">MONITOREO DE FLOTILLA</h3>
        <h4 className="monitoreo-subtitle">Hábitos de manejo e inteligencia vial</h4>
        <ul className="monitoreo-desc">
          <li>Monitoreo GPS en tiempo real y recreación de rutas.</li>
          <li>Video vigilancia en tiempo real.</li>
          <li>Hábitos de manejo y generación de ahorros: combustible,
            multas, seguros, accidentes, etc.
          </li>
          <li>Descarga remota de evidencias en video.</li>
          <li>Alertas y SOS en el celular, email o pantalla.</li>
          <li>Servicio de Centro de Monitoreo.</li>
          <li>Conteo de pasajeros.</li>
          <li>Estudios de Factibilidad y de Factor de Operación.</li>
          <li>Estudios de impacto de Movilidad (sector construcción).</li>
        </ul>
      </div>
      <div className="monitoreo-detail">
        <div className="monitoreo-detail-left">
          <h4 className="monitoreo-detail-title">BENEFICIOS</h4>
          <p>
            Nuestra plataforma, a diferencia de los productos de monitoreo <br/>
            GPS que hay en el mercado, es una solución integral que <br/>
            cuenta con módulos robustos para evaluar y dar seguimiento <br/>
            al uso que los operadores dan a las unidades además de <br/>
            monitorearlas en tiempo real y comparar rutas reales contra <br/>
            las planeadas.
          </p>
        </div>
        <div className="monitoreo-detail-right">
          <p>
            Solución integral que <br/>
            cuenta con módulos <br/>
            robustos para evaluar <br/>
            y dar seguimiento <br/>
            al uso que los operadores <br/>
            dan a las unidades.
          </p>
        </div>
      </div>
      <ProductsFooter/>
    </>
  )
}

export default Monitoreo