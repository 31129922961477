import React, { useEffect } from 'react'
import './software.css'
import ProductsFooter from '../footer/ProductsFooter';

const Software = ({setHome}) => {
  useEffect(() => {setHome('products'); window.scrollTo(0, 0)}) 
  return (
    <>
      <div className="software-container">
        <h3 className="software-title">SOFTWARE DE SEGUIMIENTO</h3>
        <h4 className="software-subtitle">de tickets de servicio</h4>
        <p className="software-desc">
        Sistema de seguimiento de solicitudes de atención servicios, entrega de productos, seguimiento a trabajos 
        y proyectos realizados por contratistas, SLAs, evidencia, actores. Generación de reportes e informes.
        </p>
        <img src="https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts%2FElementos%20neobase-12.png?alt=media&token=4a4a0434-ceb3-42df-99b7-fb07e1d4468f" alt="Fast ticket logo"/>
      </div>
      <div className="software-detail">
        <div className="software-detail-left">
          <p>
            <strong>Fast Service</strong> es un novedoso Software de Seguimiento a <br/>
            Solicitudes operativas, mantenimiento, finanzas, recursos hu- <br/>
            manos o de cualquier área que nuestros clientes tengan interés.
          </p>          
          <h5 className="software-detail-subtitle-bold">Funciones:</h5>
          <ul>
            <li>Customizable a necesidades específicas de cada empresa.</li>
            <li>Generación de solicitudes de servicio rápida en PC <br/>
              o dispositivos móviles.
            </li>
            <li>Asignación y notificación automática al responsable <br/>
              o solucionador de la solicitud.
            </li>
            <li>Asignación de perfiles de acuerdo a la responsabilidad de <br/>
              cada usuario.
            </li>
            <li>Asignación de prioridad de la solicitud.</li>
            <li>Visualización de reportes para toma de decisiones.</li>
          </ul>
        </div>
        <div className="software-detail-right">
          <p>
            Sistema integral
            <br/>de seguimiento de
            <br/>solicitudes y generación
            <br/>de reportes.
          </p>
        </div>
      </div>
      <ProductsFooter/>
    </>
  )
}

export default Software