import React, { useEffect } from 'react'
import './kiosks.css'
import ProductsFooter from '../footer/ProductsFooter';

const Kiosks = ({setHome}) => {
  useEffect(() => {setHome('products'); window.scrollTo(0, 0)}) 
  return (
    <>
      <div className="kiosks-container">
        <h3 className="kiosks-title">KIOSKOS DIGITALES</h3>
        <h4 className="kiosks-subtitle">Diseño, integración y desarrollo</h4>
        <p className="kiosks-desc">
          Buscamos la satisfacción total de nuestros clientes, por eso
          personalizamos sus kioskos de acuerdo a las características 
          que más cubren sus expectativas.
        </p>
      </div>
      <div className="kiosks-detail">
        <div className="kiosks-detail-left">
          <h4 className="kiosks-detail-title">Diseño, integración, desarrollo y comercialización de software de administración de contenido – Digital Signage</h4>
          <h5 className="kiosks-detail-subtitle">Nos adaptamos a las necesidades de cada cliente</h5>
          <ul>
            <li>Mapas de ubicación.</li>
            <li>Carrusel de spots publicitarios.</li>
            <li>Pago de servicios.</li>
            <li>Funcionalidades operativas o administrativas de
              negocios entre otros.
            </li>
          </ul>
          <h4 className="kiosks-detail-title">KIOSKOS IN-DOOR/OUT-DOOR</h4>
          <h5 className="kiosks-detail-subtitle-bold">Una o dos pantallas</h5>
          <ul>
            <li>Pantallas interactivas touch.</li>
            <li>Pantallas LED.</li>
            <li>Elección de modelos de kioskos de nuestro catálogo
              o desarrollamos kioskos sobre diseño.
            </li>
          </ul>
        </div>
        <div className="kiosks-detail-right">
          <p>
            Diseñamos y fabricamos <br/>
            kioscos a la medida, <br/>
            desarrollamos y comercializamos <br/>
            software para la administración <br/>
            de contenidos y más.
          </p>
        </div>
      </div>
      <ProductsFooter/>
    </>
  )
}

export default Kiosks