import React, { useEffect } from 'react'
import './pantallas.css'
import ProductsFooter from '../footer/ProductsFooter';

const Pantallas = ({setHome}) => {
  useEffect(() => {setHome('products'); window.scrollTo(0, 0)}) 
  return (
    <>
    <div className="pantallas-container">
      <h3 className="pantallas-title">PANTALLAS MODULARES</h3>
      <h4 className="pantallas-subtitle">Pantallas Modulares Led para exterior e interior</h4>
      <p className="pantallas-desc">
        Venta, configuración e instalación de pantallas modulares <br/>
        (Unilumin MR).
      </p>
    </div>
    <div className="pantallas-detail">
      <div className="pantallas-detail-left">
        <h4 className="kiosks-detail-title">PANTALLAS MODULARES</h4>
        <h5 className="kiosks-detail-subtitle">Ideales para:</h5>
        <ul>
          <li>Conciertos.</li>
          <li>Centros comerciales.</li>
          <li>Publiciddad exterior.</li>
          <li>Convenciones.</li>
          <li>Estadios deportivos.</li>
          <li>Gasolinerías.</li>
          <li>Aeropuertos.</li>
        </ul>
        <h5 className="kiosks-detail-subtitle-bold">Ofrecemos:</h5>
        <ul>
          <li>3 años de garantía.</li>
          <li>Soporte y mantenimiento sin costo.</li>
          <li>Entrega sin costo extra en cualquier 
            parte de la República Mexicana.
          </li>
        </ul>
      </div>
      <div className="pantallas-detail-right">
        <p>
          3 años de garantía, <br/>
          soporte y mantenimiento <br/>
          sin costo adicional.
        </p>
      </div>
    </div>
    <ProductsFooter/>
    </>
  )
}

export default Pantallas