import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import fire from '../../helpers/firebase'

export const BlogProfile = ({history, setHome}) => {

    const [user, setUser] = useState({})
    const [userChanged, setUserChanged] = useState(false)
    const [loadingPic, setLoadingPic] = useState(false)

    const handleInput = e => {
        setUser({...user, [e.target.name]: e.target.value})
    }
    
    const logOut = () => {
        fire.auth().signOut()
        localStorage.removeItem('user')
        return history.push('/blog/login')
    }

    const fetchProfile = () => {
        let user = JSON.parse(localStorage.getItem('user'))
        setUser(user)
    }

    const updateProfile = () => {
        let newUser = {
            ...user
        }
        fire.firestore().collection('users').doc(user.uid).set(newUser)
        .then(res => {
            localStorage.setItem('user', JSON.stringify(newUser))
            setUserChanged(true)
            setTimeout(() => {
                setUserChanged(false)
            }, 5000)
        })
    }

    const uploadProfilePic = e => {
        let profilePicture = e.target.files[0]
        let uploadTask = fire.storage().ref(`/blog/profilePictures/${user.uid}`).put(profilePicture)
        uploadTask.on('state_changed',
            snapshot => setLoadingPic(true),
            err =>{
                setLoadingPic(false)
                return alert('Ocurrió un error al subir la imagen, intenta nuevamente')
            },
            () => {
                fire.storage().ref(`/blog/profilePictures/${user.uid}`).getDownloadURL()
                .then( firebaseUrl => {
                    fire.firestore().collection('users').doc(user.uid).update({
                        profilePic: firebaseUrl
                    })
                    setLoadingPic(false)
                    setUser({...user, profilePic: firebaseUrl})
                    localStorage.setItem('user', JSON.stringify({...user, profilePic:firebaseUrl}))
                })
            }
        )
    }

    useEffect(() => {
        fetchProfile()
        setHome('blog')
    }, [])

    return (
        <div className='blog-container'>
            <div className='sub-nav'>
                <h2>Mi perfil</h2>
                <div className='sub-nav-buttons'>
                    <Link to='/blog/manager/my-posts'><p>Mis posts</p></Link>
                    <Link to='/blog/manager'><p style={{backgroundColor: '#039DDE'}}>Nuevo post</p></Link>
                </div>
            </div>
            <div className='profile-container'>
                <p>Imagen de perfil</p>
                <div style={{backgroundImage: `url(${user.profilePic})`}} className='profile-pic'>
                    <div onClick={() => document.getElementById('profilePic').click()} className="edit-layer">
                        <p>{loadingPic ? 'Subiendo...' : 'Editar'}</p>
                    </div>
                    <input style={{display:'none'}} onChange={uploadProfilePic} id='profilePic' type="file" name="cover" accept="image/*"/>
                </div>
                <p>Nombre y primer apellido</p>
                <input type="text" name="name" value={user.name} onChange={handleInput}/>
                <p>Puesto / escolaridad</p>
                <input type="text" name="position" value={user.position} onChange={handleInput}/>
                <p>Correo</p>
                <input type="text" name="email" value={user.email} disabled/>
                <div className='update-profile-button' onClick={updateProfile}>
                    <p>Actualizar datos</p>
                </div>
                {
                    userChanged ?
                        <div style={{marginTop: '2rem'}}>
                            <span style={{color: '##02AE43', fontSize: '1rem'}}>Usuario actualizado correctamente</span>
                        </div>
                    :
                        null
                }
                <div style={{marginTop: '2rem'}}>
                    <span style={{color: '#039DDE', fontSize: '1.2rem', cursor: 'pointer'}} onClick={logOut}>Cerrar sesión</span>
                </div>
            </div>
        </div>
    )
}
