import React from 'react'
import './clients.css'
import Carousel from './Carousel';

const Clients = () => {
    return (
        <div id="clients">
            <h2 className="clients-title">CASOS DE ÉXITO</h2>
            <Carousel />
        </div>
    )
}

export default Clients
