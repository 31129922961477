import React from 'react'
import { slide as Menu } from "react-burger-menu";
import './navbar.css'

const Sidebar = ({open, toggleSide}) => {
    return (
            <Menu isOpen={open} right>
                <a onClick={() => toggleSide(false)} className="menu-item" href="#home">
                    Inicio
                </a>

                <a className="menu-item" href="#solutions">
                    Soluciones
                </a>
                
                <a className="menu-item" href="#products">
                    Productos
                </a>
                
                <a className="menu-item" href="#us">
                    Nosotros
                </a>

                <a className="menu-item" href="#clients">
                    Clientes
                </a>

                <a className="menu-item" href="/blog">
                    Blog
                </a>

                <a className="menu-item" href="#contact">
                    Contacto
                </a>
            </Menu>
    )
}

export default Sidebar