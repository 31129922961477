import React, { useEffect } from 'react'
import './Neostudio.css'
import ProductsFooter from '../../products/footer/ProductsFooter';

const Neostudio = ({setHome}) => {

    useEffect(() => {setHome('neostudio'); window.scrollTo(0, 0)}) 
    return (
        <>
            <div className="neostudio-container"></div>
            <section className="neostudio-section">
                <div className="neostudio-detail-title">
                    <hr/>
                    <span>NOSOTROS</span>
                </div>
                <div className="neostudio-detail">
                    <img src="/img/design/frase.png" alt="conceptos fuertes,diferencias reales"/>
                    <p>
                        Somos una agencia dinámica, creativa, apasionada con una
                        curiosidad inquebrantable y una apertura al mundo que nos
                        rodea, descubrimos las necesidades de nuestros clientes.
                        Conocimientos que siempre forman el punto de partida para
                        nuestro trabajo estratégico, metódico, funcional y creativo.
                    </p>                
                </div>
            </section>
            <section className="neostudio-section gray-section">
                <div className="neostudio-detail-title">
                    <hr/>
                    <span>¿QUÉ HACEMOS?</span>
                </div>
                <div className="neostudio-detail">
                    <p>
                        Nuestras áreas trabajan en estrecha colaboración con los clientes,
                        ayudándolos a resolver los problemas más grandes de sus negocios.
                        Nuestro enfoque se centra en crear una experiencia perfecta en
                        todos los puntos de contacto digitales y físicos. Esta experiencia
                        de marca interdisciplinada da como resultado una relación mucho
                        más consistente, impactante y valiosa.
                    </p>                
                    <img src="/img/design/esquema.png" alt="que hacemos neobase"/>
                </div>
            </section>
            <section className="neostudio-section">
                <div className="neostudio-detail-title">
                    <hr/>
                    <span>SERVICIOS</span>
                </div>
                <div className="neostudio-services">
                    <div className="neo-service-container">
                        <img src="/img/design/design.png" alt="servicio de diseño"/>
                        <h2>DISEÑO</h2>
                        <ul>
                            <li>Diseño de campañas</li>
                            <li>Naming</li>
                            <li>Branding</li>
                            <li>Manual de identidad</li>
                            <li>Optimización de diseño</li>
                            <li>Diseño editorial</li>
                            <li>Diseño web</li>
                            <li>Animación</li>
                            <li>Video</li>
                            <li>Fotografía</li>
                        </ul>
                    </div>
                    <div className="neo-service-container">
                        <img src="/img/design/web.png" alt="servicio de diseño web"/>
                        <h2>WEB</h2>
                        <ul>
                            <li>Creación de web</li>
                            <li>Landing page</li>
                            <li>E-commerce</li>
                            <li>Mailing</li>
                            <li>APPS</li>
                        </ul>
                    </div>
                    <div className="neo-service-container">
                        <img src="/img/design/mkt.png" alt="servicio de marketing"/>
                        <h2>MARKETING</h2>
                        <ul>
                            <li>Planes de MKT</li>
                            <li>Implementación de plan</li>
                            <li>Benchmark</li>
                            <li>Reportes</li>
                            <li>Estrategias comerciales</li>
                        </ul>
                    </div>
                    <div className="neo-service-container">
                        <img src="/img/design/sm.png" alt="servicio de marketing"/>
                        <h2>SOCIAL MEDIA</h2>
                        <ul>
                            <li>Optimización digital</li>
                            <li>SEO / SEM</li>
                            <li>Segmentación</li>
                            <li>Estrategias digitales</li>
                            <li>Campañas</li>
                            <li>Engagement</li>
                            <li>Creación de contenido</li>
                            <li>Leads management</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <div className="neostudio-gallery">
                    <div style={{backgroundImage:`url(/img/design/img1.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img2.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img3.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img4.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img5.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img6.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img7.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img8.png)`}} className="neo-gallery-image"></div>
                    <div style={{backgroundImage:`url(/img/design/img9.png)`}} className="neo-gallery-image"></div>
                </div>
            </section>
            <ProductsFooter />
        </>
    )
}

export default Neostudio
