import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './productshome.css'

const kiosco = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fbg%2Fkiosco-bg.png?alt=media&token=947616b9-9e20-4aed-abbd-f75ac3ae6028'
const mobiliario = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fbg%2Fmobiliario-bg.png?alt=media&token=49d33195-a905-40e4-8a4c-f4e487ceb571'
const fastService = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fbg%2FfastService-bg.png?alt=media&token=5c2e9983-a783-4d45-a56d-a3b0b663838f'
const pantallas = '/img/modular.png'
const flotillas = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fbg%2Fflotillas-bg.png?alt=media&token=a0576665-393d-4c67-9c70-e4bbcc3249d1'

const kioscoIcon = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fkiosko.png?alt=media&token=69196114-8a4d-4185-a2c3-f9426817e9d4'
const mobiliarioIcon = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fmobiliario.png?alt=media&token=fdef5497-5ede-4e4b-8cdd-ca8635dd02a9'
const fastServiceIcon = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2FfastService.png?alt=media&token=c97650c8-9e16-411d-a4a2-a41c88c7817d'
const pantallasIcon = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fpantallas.png?alt=media&token=c760438d-1118-493c-8d6e-dc68e99d71c6'
const flotillasIcon = 'https://firebasestorage.googleapis.com/v0/b/neobase-web.appspot.com/o/img%2Fproducts-home%2Fflotillas.png?alt=media&token=e8f569d7-16d9-435a-8ad4-df63bc1d5292'

const ProductsHome = () => {
    const [ kioscoBg, setKiosco ] = useState(0)
    const [ mobiliarioBg, setMobiliario ] = useState(0)
    const [ fastServiceBg, setFastService ] = useState(1)
    const [ pantallasBg, setPantallas ] = useState(0)
    const [ flotillasBg, setFlotillas ] = useState(0)

    return (
        <div className="products-container" id="products-home">
            <img className="products-bg" style={{opacity: kioscoBg}} src={kiosco} alt="productos"/>
            <img className="products-bg" style={{opacity: mobiliarioBg}} src={mobiliario} alt="productos"/>
            <img className="products-bg" style={{opacity: fastServiceBg}} src={fastService} alt="productos"/>
            <img className="products-bg" style={{opacity: pantallasBg}} src={pantallas} alt="productos"/>
            <img className="products-bg" style={{opacity: flotillasBg}} src={flotillas} alt="productos"/>
            <h2 className="products-title">PRODUCTOS</h2>
            <div className="products-icons">
                <div onMouseOver={() => {setKiosco(1); setMobiliario(0); setFastService(0); setPantallas(0); setFlotillas(0)}} className="icon">
                    <Link to="/kioskos">
                        <img style={kioscoBg !== 1 ? {filter: 'grayscale(70%)'} : {filter: 'grayscale(0%)'}} src={kioscoIcon} alt="kiosco neobase Icon"/>
                        <p style={kioscoBg !== 1 ? {fontWeight:'normal'} : {fontWeight:'bold', fontSize:"1.1rem"}}>KIOSCOS DIGITALES</p>
                    </Link>
                </div>
                <div onMouseOver={() => {setKiosco(0); setMobiliario(1); setFastService(0); setPantallas(0); setFlotillas(0)}} className="icon">
                    <Link to="/mobiliario">
                        <img style={mobiliarioBg !== 1 ? {filter: 'grayscale(70%)'} : {filter: 'grayscale(0%)'}} src={mobiliarioIcon} alt="mobiliarioneobase Icon"/>
                        <p style={mobiliarioBg !== 1 ? {fontWeight:'normal'} : {fontWeight:'bold', fontSize:"1.1rem"}}>MOBILIARIO URBANO</p>
                    </Link>
                </div>
                <div onMouseOver={() => {setKiosco(0); setMobiliario(0); setFastService(1); setPantallas(0); setFlotillas(0)}} className="icon">
                    <Link to="/software">
                        <img style={fastServiceBg !== 1 ? {filter: 'grayscale(70%)'} : {filter: 'grayscale(0%)'}} src={fastServiceIcon} alt="fastServiceneobase Icon"/>
                        <p style={fastServiceBg !== 1 ? {fontWeight:'normal'} : {fontWeight:'bold', fontSize:"1.1rem"}}>SOFTWARE <br/> FAST SERVICE</p>
                    </Link>
                </div>
                <div onMouseOver={() => {setKiosco(0); setMobiliario(0); setFastService(0); setPantallas(1); setFlotillas(0)}} className="icon">
                    <Link to="/pantallas">
                        <img style={pantallasBg !== 1 ? {filter: 'grayscale(70%)'} : {filter: 'grayscale(0%)'}} src={pantallasIcon} alt="pantallasneobase Icon"/>
                        <p style={pantallasBg !== 1 ? {fontWeight:'normal'} : {fontWeight:'bold', fontSize:"1.1rem"}}>PANTALLAS MODULARES</p>
                    </Link>
                </div>
                <div onMouseOver={() => {setKiosco(0); setMobiliario(0); setFastService(0); setPantallas(0); setFlotillas(1)}} className="icon">
                    <Link to="/monitoreo">
                        <img style={flotillasBg !== 1 ? {filter: 'grayscale(70%)'} : {filter: 'grayscale(0%)'}} src={flotillasIcon} alt="flotillasneobase Icon"/>
                        <p style={flotillasBg !== 1 ? {fontWeight:'normal'} : {fontWeight:'bold', fontSize:"1.1rem"}}>MONITOREO FLOTILLAS</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ProductsHome
